import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Pagination } from '../pagination/pagination';
import { map } from 'rxjs/operators';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private http: HttpClient) { }

  public async get<T>(url: string, options?: { withCredentials?: boolean }): Promise<T> {
    return lastValueFrom(this.http.get<T>(url, options));
  }

  public async post<T, K>(url: string, body: K, options?: { withCredentials?: boolean }): Promise<T> {
    return lastValueFrom(this.http.post<T>(url, body, options));
  }

  public async postSearchPaginated<T, K>(
    url: string,
    body: K,
  ): Promise<Pagination<T>> {
    return lastValueFrom(
      this.http
        .post<Pagination<T>>(url, body)
        .pipe(map((res: Pagination<T>) => Pagination.fromJson<T>(res))),
    );
  }

  public async put<T, K>(url: string, body: K): Promise<T> {
    return lastValueFrom(this.http.put<T>(url, body));
  }

  public async delete<T>(url: string): Promise<T> {
    return lastValueFrom(this.http.delete<T>(url));
  }
}
