import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-notary-office-form',
  templateUrl: './notary-office-form.component.html',
  styleUrls: ['./notary-office-form.component.scss'],
})
export class NotaryOfficeFormComponent {
  @Input() groupSelect: string;
  @Input() detailsFormGroup: FormGroup;
  @Input() submitted: boolean;
  @Input() isModificationMode: boolean;
  @Input() isCreate: boolean;
  @Input() CRPCENExists: boolean;
  @Output() onSubmit = new EventEmitter<void>();
  @Output() cancel = new EventEmitter<void>();

  constructor() { }
  public get f() {
    return this.detailsFormGroup.controls;
  }

  emitEventSubmit() {
    this.onSubmit.emit();
  }

  emitEventCancel() {
    this.cancel.emit();
  }
}
