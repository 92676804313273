<br />
<div class="row">
  <div class="col-xl-6">
    <div class="card">
      <div class="card-header d-flex align-items-center">
        <div class="row m-0 w-100">
          <div class="col-sm-8">
            <h5 class="card-title">
              <span *ngIf="currentUser.isNotary"
                >Administration des Offices Notariaux :</span
              >
              <span *ngIf="currentUser.isMetropole"
                >Administration des groupes :</span
              >
              <span> ({{ displayedGroups.length }}) </span>
            </h5>
          </div>
          <div
            class="col-sm-4"
            style="display: flex; justify-content: flex-end"
          >
            <input
              *ngIf="currentUser.isAdmin"
              class="button button-primary me-1"
              type="button"
              value="Créer un groupe"
              (click)="openModal()"
            />
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="row mt-3">
          <small *ngIf="isDelClic && !isDisable"
            >La supprésion n'a pas fonctionné.</small
          >
        </div>
        <div class="input-whith-icon">
          <input
            id="searchGroupInput"
            class="form-control"
            [(ngModel)]="searchTerm"
            placeholder="Recherche..."
            (keyup)="filterOffices()"
          />

          <div (click)="clearSearchTerm()" class="icon">
            <fa-icon
              *ngIf="searchTerm.length > 0"
              icon="circle-xmark"
            ></fa-icon>
          </div>
        </div>
        <div class="row mt-3">
          <table aria-describedby="liste des groupes">
            <thead>
              <tr>
                <th scope="col"></th>
                <th scope="col" sortable="CRPCEN" (sort)="onSort($event)">
                  Code CRPCEN
                </th>
                <th scope="col" sortable="label" (sort)="onSort($event)">
                  Nom
                </th>
                <th scope="col">Référent</th>
                <th scope="col" sortable="type" (sort)="onSort($event)">
                  Type
                </th>
                <th *ngIf="currentUser?.isAdmin" scope="col">Action</th>
              </tr>
            </thead>
            <tbody *ngIf="displayedGroups?.length > 0">
              <tr
                *ngFor="let group of displayedGroups"
                [ngClass]="group.isActive ? 'hover-link' : 'disabled-row'"
              >
                <td></td>
                <td (click)="openGroup(group)">{{ group?.CRPCEN }}</td>
                <td (click)="openGroup(group)">{{ group?.label }}</td>
                <td (click)="openGroup(group)">{{ group?.referee }}</td>
                <td (click)="openGroup(group)">
                  {{ getTypeGroup(group) }}
                </td>

                <td *ngIf="currentUser?.isAdmin">
                  <input
                    *ngIf="currentUser?.isAdmin"
                    type="button"
                    class="button button-small"
                    (click)="openModal(group.id)"
                    value="Modifier"
                  />
                  <input
                    *ngIf="currentUser?.isAdmin"
                    type="button"
                    class="button button-small"
                    (click)="disableGroup(group.id)"
                    value="Désactiver"
                    [disabled]="
                      currentUser?.groupId == group.id || !group.isActive
                    "
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <p *ngIf="groups?.length == 0">Aucun groupe n'a été créé.</p>
        </div>
      </div>
    </div>
  </div>
  <div class="col-xl-6">
    <div
      id="detailsTab"
      class="card"
      *ngIf="currentGroup && notaries?.length > 0"
    >
      <div class="card-header">
        <div class="row d-flex align-items-center">
          <div class="col-sm-8 d-flex align-items-center">
            <h5 class="card-title m-0" *ngIf="currentUser.isNotary">
              Administrer l'office : {{ currentGroup.label }}
            </h5>
            <h5 class="card-title m-0" *ngIf="currentUser.isMetropole">
              Administrer le groupe : {{ currentGroup.label }}
            </h5>
          </div>
          <div class="col-sm-4 d-flex justify-content-center">
            <input
              class="button button-primary"
              type="button"
              *ngIf="
                (currentGroup.type === 'NOTARY_OFFICE' ||
                  currentGroup.type === 'CONSULTING_EXT') &&
                (currentUser.isAdmin || currentUser.isReferee)
              "
              [value]="
                currentGroup.type === 'NOTARY_OFFICE'
                  ? 'Créer un Notaire'
                  : 'Créer un Utilisateur'
              "
              (click)="openModalNotary(currentGroup.id)"
            />
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <small *ngIf="confirm && !isDisable"
            >La suppréssion n'a pas fonctionné.</small
          >
        </div>

        <div class="row mt-3">
          <table
            aria-describedby="Liste des utilisateurs de l'office notarial selectionné"
          >
            <thead>
              <tr>
                <th scope="col"></th>
                <th scope="col" sortable="name" (sort)="onSortNotary($event)">
                  Utilisateur
                </th>
                <th scope="col" sortable="email" (sort)="onSortNotary($event)">
                  Adresse Email
                </th>
                <th scope="col">Rôle</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody *ngIf="notaries?.length > 0">
              <tr
                *ngFor="let notary of notaries"
                [ngClass]="notary.isActive ? 'hover-link' : 'disabled-row'"
              >
                <td></td>
                <td (click)="openModalNotary(notary.groupId, notary)">
                  {{ notary?.name }}
                </td>
                <td (click)="openModalNotary(notary.groupId, notary)">
                  {{ notary?.email }}
                </td>
                <td
                  *ngIf="
                    notary.isNotary && currentGroup?.type === 'NOTARY_OFFICE'
                  "
                >
                  {{ notary?.isReferee ? 'Référent' : 'Notaire' }}
                </td>
                <td
                  *ngIf="
                    notary.isMetropole && currentGroup?.type === 'SOCIAL_AID'
                  "
                >
                  {{ notary?.isReferee ? 'Référent' : 'Agent' }}
                  {{ notary?.isAdmin ? '/ Admin' : '' }}
                </td>
                <td *ngIf="currentGroup?.type === 'CONSULTING_INT'">
                  {{ notary?.isReferee ? 'Référent' : 'Consultant interne' }}
                </td>
                <td *ngIf="currentGroup?.type === 'CONSULTING_EXT'">
                  {{ notary?.isReferee ? 'Référent' : 'Consultant externe' }}
                </td>

                <td>
                  <div
                    ngbDropdown
                    #myDrop="ngbDropdown"
                    container="body"
                    *ngIf="currentGroup?.type === 'SOCIAL_AID'"
                  >
                    <button
                      (click)="initFormRangeAlphabet()"
                      type="button"
                      class="button button-small"
                      ngbDropdownToggle
                    >
                      Plage Alphabétique
                    </button>
                    <div
                      ngbDropdownMenu
                      aria-labelledby="dropdownForm1"
                      style="max-width: 325px; padding-top: 0"
                    >
                      <div
                        class="d-flex flex-row p-2 myAlert"
                        *ngIf="plageErrorExist"
                      >
                        <fa-icon icon="exclamation-triangle"></fa-icon>
                        <p>
                          Impossible d'enregistrer la plage alphabétique.
                          Celle-ci est déjà attribuée ou chevauche une plage
                          existante de
                          <span class="fw-bold">"{{ plageErrorExist }}"</span>.
                          Veuillez réessayer avec une plage différente.
                        </p>
                      </div>

                      <form class="px-4 py-3" [formGroup]="rowFormGroup">
                        <div class="d-flex flex-column mb-3">
                          <div class="input-group">
                            <input
                              maxlength="3"
                              [class.is-invalid]="f.start.errors"
                              formControlName="start"
                              maxlength="3"
                              class="form-control m-0"
                              placeholder="Début"
                              onkeyup="this.value=this.value.toUpperCase();"
                            />
                          </div>
                          <div>
                            <small
                              class="text-danger pl-1"
                              *ngIf="
                                rowFormGroup.get('start').hasError('pattern')
                              "
                            >
                              *Utilisez uniquement des lettres
                            </small>
                          </div>
                        </div>
                        <div class="d-flex flex-column mb-3">
                          <div class="input-group">
                            <input
                              maxlength="3"
                              [class.is-invalid]="
                                rowFormGroup.get('end').errors
                              "
                              class="form-control m-0"
                              formControlName="end"
                              maxlength="3"
                              placeholder="Fin"
                              onkeyup="this.value=this.value.toUpperCase();"
                            />
                          </div>
                          <small
                            class="text-danger pl-1"
                            *ngIf="rowFormGroup.get('end').hasError('pattern')"
                          >
                            *Utilisez uniquement des lettres
                          </small>
                          <small
                            class="text-danger pl-1"
                            *ngIf="
                              rowFormGroup
                                .get('end')
                                .hasError('rangeInvalid') ||
                              rowFormGroup.get('start').hasError('rangeInvalid')
                            "
                          >
                            *La plage de fin doit être supérieure ou égale à la
                            plage de début.
                          </small>
                        </div>
                        <div class="d-flex col-12 flex-row-reverse">
                          <button
                            class="button button-small button-primary"
                            [disabled]="
                              rowFormGroup.invalid ||
                              !rowFormGroup.get('start').value ||
                              !rowFormGroup.get('end').value
                            "
                            (click)="
                              addAphabeticalRange(notary, rowFormGroup, $event)
                            "
                          >
                            Valider
                          </button>
                          <button
                            (click)="myDrop?.close()"
                            class="button button-small button-secondary"
                          >
                            Annuler
                          </button>
                        </div>
                      </form>
                      <div
                        *ngIf="
                          notary?.alphabeticalRanges &&
                          notary?.alphabeticalRanges?.length > 0
                        "
                      >
                        <ul class="list-group d-flex flex-column p-4">
                          <li
                            class="list-group-item d-flex justify-content-between align-items-center"
                            *ngFor="
                              let range of getAlphabeticalRanges(
                                notary.alphabeticalRanges
                              )
                            "
                          >
                            {{ range.rangeStart }} - {{ range.rangeEnd }}
                            <button
                              class="button button-trash"
                              (click)="removeAphabeticalRange(range, notary)"
                            >
                              <fa-icon icon="trash" size="1x"></fa-icon>
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <input
                    type="button"
                    class="button button-small"
                    (click)="setReferee(notary)"
                    value="Définir comme référent"
                    [disabled]="notary.isReferee || !notary.isActive"
                  /><br />
                  <input
                    *ngIf="
                      !notary.isActive &&
                      currentUser?.group?.type !== 'CONSULTING_EXT'
                    "
                    type="button"
                    class="button button-small"
                    (click)="setIsActiveUserProps(notary)"
                    value="Réactiver"
                    class="button button-small button-primary"
                    [disabled]="notary?.name === currentUser?.name"
                  />
                  <input
                    *ngIf="
                      notary.isActive &&
                      currentUser?.group?.type !== 'CONSULTING_EXT'
                    "
                    type="button"
                    class="button button-small"
                    (click)="setIsActiveUserProps(notary)"
                    [disabled]="
                      notary?.name === currentUser?.name || notary?.isReferee
                    "
                    value="Désactiver"
                  />
                  <br />

                  <input
                    *ngIf="
                      currentUser.isAdmin &&
                      currentGroup.type !== 'NOTARY_OFFICE'
                    "
                    type="button"
                    class="button button-small"
                    [value]="
                      notary.isAdmin
                        ? 'Retirer les droits Administrateur'
                        : 'Donner les droits Administrateur'
                    "
                    (click)="changeRoleSubmit(notary)"
                    [disabled]="notary?.name === currentUser?.name"
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <p *ngIf="notaries?.length == 0">
            Il n'y a pas de notaires dans cet office.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
