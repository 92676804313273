import { Component, OnInit } from '@angular/core';
import { Mdm } from 'src/admin/mdm.entity';
import { AdminService } from '../../admin.service';

@Component({
  selector: 'app-mdm',
  templateUrl: './mdm.component.html',
})
export class MdmComponent implements OnInit {
  public mdms: Mdm[];
  constructor(private adminService: AdminService) {}

  public async ngOnInit(): Promise<void> {
    this.mdms = await this.adminService.getMdms();
  }
}
