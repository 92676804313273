import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AdminService } from 'src/admin/admin.service';
import { UserDto } from 'src/auth/userProfileDto';
import { SocialAid } from 'src/shared/interface/socialAid.interface';

@Component({
  selector: 'app-social-aid-form',
  templateUrl: './social-aid-form.component.html',
  styleUrls: ['./social-aid-form.component.scss'],
})
export class SocialAidFormComponent implements OnInit {
  @Input() group: string;
  @Input() detailsFormGroup: FormGroup;
  @Input() submitted: boolean;
  @Input() isModificationMode: boolean;
  @Input() isCreate: boolean;
  @Input() CRPCENExists: boolean;
  @Output() onSubmit = new EventEmitter<void>();
  @Output() cancel = new EventEmitter<void>();

  socialAids_array: SocialAid[];
  users: UserDto[];
  constructor(private adminService: AdminService) { }
  public get f() {
    return this.detailsFormGroup.controls;
  }

  async ngOnInit() {
    this.socialAids_array = await this.adminService.getSocialAids();
    this.socialAids_array.push({ id: 0, name: 'Ambigu' });
    this.users = await this.adminService.getMetropoleUsers();
    this.users = this.users.filter((user) => !user.groupId);
  }

  public emitEventSubmit() {
    this.onSubmit.emit();
  }

  public emitEventCancel() {
    this.cancel.emit();
  }

  public compareAids(aid1, aid2) {
    return aid1 && aid2 ? aid1.name === aid2.name : aid1 === aid2;
  }
}


