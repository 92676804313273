import {
  Component,
  ElementRef,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { UserDto } from 'src/auth/userProfileDto';
import { SortEvent } from 'src/shared/sortable/sort-event';
import { AdminService } from '../../admin.service';
import { GroupTableDto } from '../office/office.table.dto';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-metropole',
  templateUrl: './metropole.component.html',
  styleUrls: ['../main.component.scss'],
})
export class MetropoleComponent implements OnInit {
  @ViewChild(NgbDropdown)
  private ngbDropdown: NgbDropdown;
  public users: UserDto[] = [];
  public groups: GroupTableDto[];
  public groupIdBulkAction: number;
  public currentUsersForBulkAction: UserDto[];
  selectAllUsers: boolean = false;
  constructor(private adminService: AdminService) { }

  public async ngOnInit() {
    this.currentUsersForBulkAction = []
    this.users = await this.adminService.getMetropoleUsers();
    this.users = this.users.filter(
      (item) => !item.hasOwnProperty('groupId') || item.groupId === null,
    );
    this.groups = await this.adminService.getGroups();
    this.groups = this.groups.filter(
      (group) => (group.type !== 'NOTARY_OFFICE' && group.type !== 'CONSULTING_EXT') && group.isActive,
    );
  }

  selectAll(ev) {
    this.selectAllUsers = ev.target.checked;
    if (this.selectAllUsers) {
      this.currentUsersForBulkAction = this.users;
    } else {
      this.currentUsersForBulkAction = [];
    }
  }

  public updateParentArray(updatedArray: UserDto[]): void {
    this.currentUsersForBulkAction = updatedArray;
  }

  public closeDropdown(): void {
    this.ngbDropdown.close();
    this.groupIdBulkAction = null;
  }

  public async submitGroupsBulkAction() {
    for (const user of this.currentUsersForBulkAction) {
      user.groupId = this.groupIdBulkAction;
      await this.adminService.updateUser(user);
    }
    this.ngOnInit();
    this.closeDropdown();
  }

  public async onSortAgent(event: SortEvent): Promise<void> {
    if (event.direction === 'asc') {
      this.users.sort((a: any, b: any) => {
        if (a[event.column] > b[event.column]) return 1;
        if (a[event.column] < b[event.column]) return -1;
        return 0;
      });
    } else if (event.direction === 'desc') {
      this.users.sort((a: any, b: any) => {
        if (a[event.column] < b[event.column]) return 1;
        if (a[event.column] > b[event.column]) return -1;
        return 0;
      });
    }
  }
}
